
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as backupsLHl2jy9HjzMeta } from "/app/packages/frontend/src/pages/backups.vue?macro=true";
import { default as findingfieldslwzdCiLeYMMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/findingfields.vue?macro=true";
import { default as index04EWNh6PDxMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/index.vue?macro=true";
import { default as notesYuKJwYpXZpMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/notes.vue?macro=true";
import { default as pdfdesignerxoxW7YJS0JMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue?macro=true";
import { default as reportfieldsLDZFkZPF98Meta } from "/app/packages/frontend/src/pages/designs/[projectTypeId]/reportfields.vue?macro=true";
import { default as _91projectTypeId_934qI4WtyZdEMeta } from "/app/packages/frontend/src/pages/designs/[projectTypeId].vue?macro=true";
import { default as indexAfoaDsnqlHMeta } from "/app/packages/frontend/src/pages/designs/index.vue?macro=true";
import { default as privatejvdhPAetUXMeta } from "/app/packages/frontend/src/pages/designs/private.vue?macro=true";
import { default as indexsfu8patRbJMeta } from "/app/packages/frontend/src/pages/index.vue?macro=true";
import { default as license7DgMGtHit1Meta } from "/app/packages/frontend/src/pages/license.vue?macro=true";
import { default as autoOH0jQETGNBMeta } from "/app/packages/frontend/src/pages/login/auto.vue?macro=true";
import { default as indexPT9d9YCvvUMeta } from "/app/packages/frontend/src/pages/login/index.vue?macro=true";
import { default as localGJ6fj8QBAEMeta } from "/app/packages/frontend/src/pages/login/local.vue?macro=true";
import { default as callback3pXrXHnx0YMeta } from "/app/packages/frontend/src/pages/login/oidc/[authProviderId]/callback.vue?macro=true";
import { default as reauth3jV0Y0Da5eMeta } from "/app/packages/frontend/src/pages/login/reauth.vue?macro=true";
import { default as _91noteId_93NhY1O1dBkGMeta } from "/app/packages/frontend/src/pages/notes/personal/[noteId].vue?macro=true";
import { default as indexLZGGykG24zMeta } from "/app/packages/frontend/src/pages/notes/personal/index.vue?macro=true";
import { default as personalkF0Gzb6JQOMeta } from "/app/packages/frontend/src/pages/notes/personal.vue?macro=true";
import { default as _91_46_46_46path_937b8O4an9lXMeta } from "/app/packages/frontend/src/pages/plugins/[...path].vue?macro=true";
import { default as pluginsdcm5MnugLMMeta } from "/app/packages/frontend/src/pages/plugins.vue?macro=true";
import { default as archivesHCsLX1bIFMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/archive.vue?macro=true";
import { default as designerB12qarz3cSMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/designer.vue?macro=true";
import { default as historyPL1igslhmdMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/history.vue?macro=true";
import { default as indexIoPPDSa0VFMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/index.vue?macro=true";
import { default as _91historyDate_93OqddQTQHLZMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue?macro=true";
import { default as indexMPRcF9uklJMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue?macro=true";
import { default as indexbFbtxcw334Meta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes/index.vue?macro=true";
import { default as notesCpGrLfPCXIMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/notes.vue?macro=true";
import { default as _91_46_46_46path_939m0gG2S25yMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/plugins/[...path].vue?macro=true";
import { default as plugins3H0ATiOAE6Meta } from "/app/packages/frontend/src/pages/projects/[projectId]/plugins.vue?macro=true";
import { default as publishLuNT5rWe5iMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/publish.vue?macro=true";
import { default as _91historyDate_93zUoHRLptjwMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue?macro=true";
import { default as indexwodnWgFquzMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue?macro=true";
import { default as indexwK2djdGoYCMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/index.vue?macro=true";
import { default as _91historyDate_93uZuQ55lHGuMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue?macro=true";
import { default as index5dH9lNxTDCMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue?macro=true";
import { default as reportingzV49fE2IJgMeta } from "/app/packages/frontend/src/pages/projects/[projectId]/reporting.vue?macro=true";
import { default as _91projectId_93u848TU3joVMeta } from "/app/packages/frontend/src/pages/projects/[projectId].vue?macro=true";
import { default as _91archiveId_93oT8q930cm3Meta } from "/app/packages/frontend/src/pages/projects/archived/[archiveId].vue?macro=true";
import { default as indexR97IuciqJBMeta } from "/app/packages/frontend/src/pages/projects/archived/index.vue?macro=true";
import { default as finishedfFbsbxa9zIMeta } from "/app/packages/frontend/src/pages/projects/finished.vue?macro=true";
import { default as indexGP2sfaVbbkMeta } from "/app/packages/frontend/src/pages/projects/index.vue?macro=true";
import { default as new4OOLIpBqk6Meta } from "/app/packages/frontend/src/pages/projects/new.vue?macro=true";
import { default as settingsbqIHipEinZMeta } from "/app/packages/frontend/src/pages/settings.vue?macro=true";
import { default as index9oxc1c9KEZMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/index.vue?macro=true";
import { default as _91noteId_93jvQZqeTlpjMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/[noteId].vue?macro=true";
import { default as indexZATkgbGYqFMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/index.vue?macro=true";
import { default as notesICqxAd8xBKMeta } from "/app/packages/frontend/src/pages/shared/[shareInfoId]/notes.vue?macro=true";
import { default as _91historyDate_93wHhcTPgphfMeta } from "/app/packages/frontend/src/pages/templates/[templateId]/history/[historyDate].vue?macro=true";
import { default as indexC0zkK8kidmMeta } from "/app/packages/frontend/src/pages/templates/[templateId]/index.vue?macro=true";
import { default as fromfindinghl0hPewQaGMeta } from "/app/packages/frontend/src/pages/templates/fromfinding.vue?macro=true";
import { default as indexf82aVyxWOeMeta } from "/app/packages/frontend/src/pages/templates/index.vue?macro=true";
import { default as apitokens2k5ZIHS9YPMeta } from "/app/packages/frontend/src/pages/users/[userId]/apitokens.vue?macro=true";
import { default as identitiesXYRtt58RDkMeta } from "/app/packages/frontend/src/pages/users/[userId]/identities.vue?macro=true";
import { default as indexkxDhsvg1ZmMeta } from "/app/packages/frontend/src/pages/users/[userId]/index.vue?macro=true";
import { default as mfawuBV9L2WssMeta } from "/app/packages/frontend/src/pages/users/[userId]/mfa.vue?macro=true";
import { default as reset_45passwordnPw9bNaBypMeta } from "/app/packages/frontend/src/pages/users/[userId]/reset-password.vue?macro=true";
import { default as _91userId_93t1ZPUuTIIlMeta } from "/app/packages/frontend/src/pages/users/[userId].vue?macro=true";
import { default as indexUhMtEUfkdkMeta } from "/app/packages/frontend/src/pages/users/index.vue?macro=true";
import { default as newU5CqpAeDexMeta } from "/app/packages/frontend/src/pages/users/new.vue?macro=true";
import { default as disableDZUtxnF25HMeta } from "/app/packages/frontend/src/pages/users/self/admin/disable.vue?macro=true";
import { default as enableY5JP4xW2lVMeta } from "/app/packages/frontend/src/pages/users/self/admin/enable.vue?macro=true";
import { default as apitokensL8bXrjwnRfMeta } from "/app/packages/frontend/src/pages/users/self/apitokens.vue?macro=true";
import { default as indexA173Q1wDfdMeta } from "/app/packages/frontend/src/pages/users/self/index.vue?macro=true";
import { default as publickeystKsZQqvUV3Meta } from "/app/packages/frontend/src/pages/users/self/publickeys.vue?macro=true";
import { default as securityjSGtGnlWffMeta } from "/app/packages/frontend/src/pages/users/self/security.vue?macro=true";
import { default as selfhS6djqnwZDMeta } from "/app/packages/frontend/src/pages/users/self.vue?macro=true";
export default [
  {
    name: "backups",
    path: "/backups",
    meta: backupsLHl2jy9HjzMeta || {},
    component: () => import("/app/packages/frontend/src/pages/backups.vue")
  },
  {
    name: _91projectTypeId_934qI4WtyZdEMeta?.name,
    path: "/designs/:projectTypeId()",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId].vue"),
    children: [
  {
    name: "designs-projectTypeId-findingfields",
    path: "findingfields",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/findingfields.vue")
  },
  {
    name: "designs-projectTypeId",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/index.vue")
  },
  {
    name: "designs-projectTypeId-notes",
    path: "notes",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/notes.vue")
  },
  {
    name: "designs-projectTypeId-pdfdesigner",
    path: "pdfdesigner",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/pdfdesigner.vue")
  },
  {
    name: "designs-projectTypeId-reportfields",
    path: "reportfields",
    component: () => import("/app/packages/frontend/src/pages/designs/[projectTypeId]/reportfields.vue")
  }
]
  },
  {
    name: "designs",
    path: "/designs",
    meta: indexAfoaDsnqlHMeta || {},
    component: () => import("/app/packages/frontend/src/pages/designs/index.vue")
  },
  {
    name: "designs-private",
    path: "/designs/private",
    meta: privatejvdhPAetUXMeta || {},
    component: () => import("/app/packages/frontend/src/pages/designs/private.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexsfu8patRbJMeta || {},
    component: () => import("/app/packages/frontend/src/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    meta: license7DgMGtHit1Meta || {},
    component: () => import("/app/packages/frontend/src/pages/license.vue")
  },
  {
    name: "login-auto",
    path: "/login/auto",
    meta: autoOH0jQETGNBMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/auto.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: indexPT9d9YCvvUMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/index.vue")
  },
  {
    name: "login-local",
    path: "/login/local",
    meta: localGJ6fj8QBAEMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/local.vue")
  },
  {
    name: "login-oidc-authProviderId-callback",
    path: "/login/oidc/:authProviderId()/callback",
    meta: callback3pXrXHnx0YMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/oidc/[authProviderId]/callback.vue")
  },
  {
    name: "login-reauth",
    path: "/login/reauth",
    meta: reauth3jV0Y0Da5eMeta || {},
    component: () => import("/app/packages/frontend/src/pages/login/reauth.vue")
  },
  {
    name: personalkF0Gzb6JQOMeta?.name,
    path: "/notes/personal",
    component: () => import("/app/packages/frontend/src/pages/notes/personal.vue"),
    children: [
  {
    name: "notes-personal-noteId",
    path: ":noteId()",
    component: () => import("/app/packages/frontend/src/pages/notes/personal/[noteId].vue")
  },
  {
    name: "notes-personal",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/notes/personal/index.vue")
  }
]
  },
  {
    name: "plugins-main",
    path: "/plugins",
    meta: pluginsdcm5MnugLMMeta || {},
    component: () => import("/app/packages/frontend/src/pages/plugins.vue"),
    children: [
  {
    name: "plugins-path",
    path: ":path(.*)*",
    component: () => import("/app/packages/frontend/src/pages/plugins/[...path].vue")
  }
]
  },
  {
    name: _91projectId_93u848TU3joVMeta?.name,
    path: "/projects/:projectId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId].vue"),
    children: [
  {
    name: "projects-projectId-archive",
    path: "archive",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/archive.vue")
  },
  {
    name: "projects-projectId-designer",
    path: "designer",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/designer.vue")
  },
  {
    name: "projects-projectId-history",
    path: "history",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/history.vue")
  },
  {
    name: "projects-projectId",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/index.vue")
  },
  {
    name: notesCpGrLfPCXIMeta?.name,
    path: "notes",
    meta: notesCpGrLfPCXIMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes.vue"),
    children: [
  {
    name: "projects-projectId-notes-noteId-history-historyDate",
    path: ":noteId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/history/[historyDate].vue")
  },
  {
    name: "projects-projectId-notes-noteId",
    path: ":noteId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes/[noteId]/index.vue")
  },
  {
    name: "projects-projectId-notes",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/notes/index.vue")
  }
]
  },
  {
    name: "plugins-project",
    path: "plugins",
    meta: plugins3H0ATiOAE6Meta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/plugins.vue"),
    children: [
  {
    name: "projects-projectId-plugins-path",
    path: ":path(.*)*",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/plugins/[...path].vue")
  }
]
  },
  {
    name: "projects-projectId-publish",
    path: "publish",
    meta: publishLuNT5rWe5iMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/publish.vue")
  },
  {
    name: reportingzV49fE2IJgMeta?.name,
    path: "reporting",
    meta: reportingzV49fE2IJgMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting.vue"),
    children: [
  {
    name: "projects-projectId-reporting-findings-findingId-history-historyDate",
    path: "findings/:findingId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/history/[historyDate].vue")
  },
  {
    name: "projects-projectId-reporting-findings-findingId",
    path: "findings/:findingId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/findings/[findingId]/index.vue")
  },
  {
    name: "projects-projectId-reporting",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/index.vue")
  },
  {
    name: "projects-projectId-reporting-sections-sectionId-history-historyDate",
    path: "sections/:sectionId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/history/[historyDate].vue")
  },
  {
    name: "projects-projectId-reporting-sections-sectionId",
    path: "sections/:sectionId()",
    component: () => import("/app/packages/frontend/src/pages/projects/[projectId]/reporting/sections/[sectionId]/index.vue")
  }
]
  }
]
  },
  {
    name: "projects-archived-archiveId",
    path: "/projects/archived/:archiveId()",
    component: () => import("/app/packages/frontend/src/pages/projects/archived/[archiveId].vue")
  },
  {
    name: "projects-archived",
    path: "/projects/archived",
    meta: indexR97IuciqJBMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/archived/index.vue")
  },
  {
    name: "projects-finished",
    path: "/projects/finished",
    meta: finishedfFbsbxa9zIMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/finished.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexGP2sfaVbbkMeta || {},
    component: () => import("/app/packages/frontend/src/pages/projects/index.vue")
  },
  {
    name: "projects-new",
    path: "/projects/new",
    component: () => import("/app/packages/frontend/src/pages/projects/new.vue")
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsbqIHipEinZMeta || {},
    component: () => import("/app/packages/frontend/src/pages/settings.vue")
  },
  {
    name: "shared-shareInfoId",
    path: "/shared/:shareInfoId()",
    meta: index9oxc1c9KEZMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/index.vue")
  },
  {
    name: notesICqxAd8xBKMeta?.name,
    path: "/shared/:shareInfoId()/notes",
    meta: notesICqxAd8xBKMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/notes.vue"),
    children: [
  {
    name: "shared-shareInfoId-notes-noteId",
    path: ":noteId()",
    meta: _91noteId_93jvQZqeTlpjMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/[noteId].vue")
  },
  {
    name: "shared-shareInfoId-notes",
    path: "",
    meta: indexZATkgbGYqFMeta || {},
    component: () => import("/app/packages/frontend/src/pages/shared/[shareInfoId]/notes/index.vue")
  }
]
  },
  {
    name: "templates-templateId-history-historyDate",
    path: "/templates/:templateId()/history/:historyDate()",
    component: () => import("/app/packages/frontend/src/pages/templates/[templateId]/history/[historyDate].vue")
  },
  {
    name: "templates-templateId",
    path: "/templates/:templateId()",
    component: () => import("/app/packages/frontend/src/pages/templates/[templateId]/index.vue")
  },
  {
    name: "templates-fromfinding",
    path: "/templates/fromfinding",
    component: () => import("/app/packages/frontend/src/pages/templates/fromfinding.vue")
  },
  {
    name: "templates",
    path: "/templates",
    meta: indexf82aVyxWOeMeta || {},
    component: () => import("/app/packages/frontend/src/pages/templates/index.vue")
  },
  {
    name: _91userId_93t1ZPUuTIIlMeta?.name,
    path: "/users/:userId()",
    component: () => import("/app/packages/frontend/src/pages/users/[userId].vue"),
    children: [
  {
    name: "users-userId-apitokens",
    path: "apitokens",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/apitokens.vue")
  },
  {
    name: "users-userId-identities",
    path: "identities",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/identities.vue")
  },
  {
    name: "users-userId",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/index.vue")
  },
  {
    name: "users-userId-mfa",
    path: "mfa",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/mfa.vue")
  },
  {
    name: "users-userId-reset-password",
    path: "reset-password",
    component: () => import("/app/packages/frontend/src/pages/users/[userId]/reset-password.vue")
  }
]
  },
  {
    name: "users",
    path: "/users",
    meta: indexUhMtEUfkdkMeta || {},
    component: () => import("/app/packages/frontend/src/pages/users/index.vue")
  },
  {
    name: "users-new",
    path: "/users/new",
    component: () => import("/app/packages/frontend/src/pages/users/new.vue")
  },
  {
    name: selfhS6djqnwZDMeta?.name,
    path: "/users/self",
    component: () => import("/app/packages/frontend/src/pages/users/self.vue"),
    children: [
  {
    name: "users-self-admin-disable",
    path: "admin/disable",
    component: () => import("/app/packages/frontend/src/pages/users/self/admin/disable.vue")
  },
  {
    name: "users-self-admin-enable",
    path: "admin/enable",
    component: () => import("/app/packages/frontend/src/pages/users/self/admin/enable.vue")
  },
  {
    name: "users-self-apitokens",
    path: "apitokens",
    component: () => import("/app/packages/frontend/src/pages/users/self/apitokens.vue")
  },
  {
    name: "users-self",
    path: "",
    component: () => import("/app/packages/frontend/src/pages/users/self/index.vue")
  },
  {
    name: "users-self-publickeys",
    path: "publickeys",
    component: () => import("/app/packages/frontend/src/pages/users/self/publickeys.vue")
  },
  {
    name: "users-self-security",
    path: "security",
    component: () => import("/app/packages/frontend/src/pages/users/self/security.vue")
  }
]
  }
]